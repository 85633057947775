<template>
  <div class="px-3 mt-5">
    <div class="row">
      <div class="col-10">
        <div class="text-start" style="margin-bottom: 6rem;">
          <label for="course" class="form-label d-block w-100 text-start">選擇課程</label>
          <div style="background: white;">
            <v-select
              label="label"
              :options="courseList"
              v-model="selectedCourses"
              :placeholder="'請選擇課程'"
              @option:selected="courseSelected">
              <div slot="no-options">找不到結果</div>
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10">
      <div class=" text-start">
        <label for="student" class="form-label d-block w-100 text-start">新增學生</label>
        <div style="background: white;">
          <v-select
            label="label"
            :options="studentList"
            v-model="selectedStudents"
            :placeholder="'請選擇學生'"
            multiple
            :reduce="item => item.id"
            @option:selected="studentSelected">
          >
            <div slot="no-options">找不到結果</div>
          </v-select>
        </div>
        <!-- {{ selectedStudents }} -->
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-end">
        <button class="btn btn-main" type="button"
          @click.prevent="submit"
          v-if="studentDropped && courseDropped && selectedCourses && selectedStudents.length > 0">新增</button>
        <button class="btn btn-main" type="button" style="background-color: grey;" v-else>新增</button>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__NewOrder',
  data() {
    return {
      courseList: [],
      studentList: [],
      courseDropped: false,
      studentDropped: false,
      selectedCourses: [],
      selectedStudents: [],
      metaTitle: '新增訂單 / 訂單 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "新增訂單 / 訂單 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '新增訂單 / 訂單 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      this.$http.get(`${this.$API_PATH}/Admin/CourseManagement/Manual`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.courseList = res.data.data.courseList.map((item) => ({ ...item, label: `${item.id} - ${item.name}` }));
        this.studentList = res.data.data.studentList.map((item) => ({ ...item, label: `${item.userName} - ${item.email}` }));

        // console.log(this.courseList, this.studentList);
      }).catch((err) => {
        // this.$router.push('/error');
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    courseSelected(opt) {
      this.courseDropped = true;
    },
    studentSelected(opt) {
      this.studentDropped = true;
    },
    submit() {
      const data = {
        courseId: this.selectedCourses.id,
        userId: this.selectedStudents,
      };
      this.$http.post(`${this.$API_PATH}/Admin/CourseManagement/Manual`, data, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        // console.log(res.data);
        Swal.fire(
          '',
          `${res.data.message}`,
          'success',
        ).then((result) => {
          if (result.isConfirmed) {
            // this.$router.push('/');
          }
        });
      }).catch((err) => {
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
  .newOrder__dropdown {
    left: 0;
    top: 3rem;
  }
</style>
